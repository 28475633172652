








































import IconCar from '@/components/icons/IconCar.vue';
import { ServicioCarpoolDisponible } from '@/models/taaxii.com/taaxiiServicioCarpoolDisponible';
import { get } from '@/services/http';
import Vue from 'vue';
import { AxiosError } from 'axios';
import { propOfType, propOfTypeArrayOf } from '@/services/utils';
import { Address } from '@/models/Address';
import { GetServiciosDisponiblesReq } from '@/models/requests-and-responses/sgv';
import { eventBus } from '@/main';
import msg from '@/services/userMsg';
import { apply } from '../../indexForm.vue';
import ServiceItem from './CarpoolServiceItem.vue';
import SectionLayout from '../SectionLayout.vue';

export default Vue.extend({
  components: {
    SectionLayout,
    ServiceItem,
    IconCar,
  },
  data() {
    return {
      services: [] as ServicioCarpoolDisponible[],
      indexOfSelectedServices: null as number | null,
      loading: false,
      thereWasAFetch: false,
      testing: true,
    };
  },
  mounted() {
  },
  props: {
    isExpanded: Boolean,
    addresses: propOfTypeArrayOf<Address | undefined>(),
    idCliente: Number,
    date: propOfType<Date | null>(null),
    dateRegreso: propOfType<Date | null>(null),
    userTriedToConfirm: Boolean,
    isLoAntesPosible: Boolean,
    service: propOfType<ServicioCarpoolDisponible | null>(null),
  },
  watch: {
    addresses() { this.fetch(); },
    idCliente() { this.fetch(); },
    date() { this.fetch(); },
    selectedServicioDisponible(val) {
      apply(this, (data) => {
        data.service = val;
      });
    },
  },
  computed: {
    origenDestinoIguales(): boolean {
      return this.addresses.length === 2
        && this.addresses[0] !== undefined
        && this.addresses[1] !== undefined
        && this.addresses[0]?.street === this.addresses[1]?.street;
    },
    headerText(): string {
      if (this.services.length > 0) {
        return 'Recorridos disponibles';
      } if (this.loading) {
        return 'Buscando recorridos...';
      } if (this.thereWasAFetch) {
        return 'Sin recorridos';
      }
      return 'Recorridos';
    },
    error() {
      if (this.userTriedToConfirm && this.services.length === 0) return 'No hay recorridos disponibles';
      if (this.userTriedToConfirm && this.indexOfSelectedServices === null) return 'Recorrido no seleccionado';
      return '';
    },
    selectedServicioDisponible(): ServicioCarpoolDisponible | null {
      if (this.indexOfSelectedServices === null) return null;
      return this.services[this.indexOfSelectedServices];
    },
  },
  methods: {
    getParsedLocationOf(address: Address): string {
      // Por algún motivo el server espera localidades escritas en una
      // localidad específica. No cualquiera sirve.
      return 'Cordoba, Cordoba';
    },
    getDateNotNull(): Date {
      const d = this.date;
      return d || new Date();
    },
    fetch(): void {
      eventBus.$emit('origenDestinoIguales', false);
      this.indexOfSelectedServices = null;
      if (this.origenDestinoIguales) {
        eventBus.$emit('origenDestinoIguales', true);
        this.$toast.error(msg.getError('same_origin_destination'));
        this.services = [];
        this.thereWasAFetch = false;
        return;
      }
      const filteredAdds = this.addresses.filter((adds) => adds) as Address[];

      if (filteredAdds.length < 2 || this.addresses.length > filteredAdds.length) {
        this.services = [];
        this.thereWasAFetch = false;
        return;
      }

      if (!this.date) return;

      const req: GetServiciosDisponiblesReq = {
        idCliente: this.idCliente,
        paradas: filteredAdds.map(({ lat, lon }) => `${lat},${lon}`).join(':'),
        fechaHoraSalida: this.date,
      };

      this.loading = true;
      this.services = [];
      get('$sgv/rest/carpool/servicios', req).then((res) => {
        this.services = res.data;
      }).catch((_: AxiosError) => {
        this.services = [];
      }).finally(() => {
        this.loading = false;
        this.thereWasAFetch = true;
      });
    },
    onItemSelection(index: number) {
      this.indexOfSelectedServices = index;
    },
  },
});
