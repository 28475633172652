






































import IconCalendar from '@/components/icons/IconCalendar.vue';
import Vue from 'vue';
import { propOfType } from '@/services/utils';
import SectionLayout from '../SectionLayout.vue';
import { AddressOfForm, apply } from '../../indexForm.vue';
import { Metadata } from '../../../CreateWeb.vue';
import DatePicker from './DatePicker.vue';

export default Vue.extend({
  components: {
    SectionLayout,
    IconCalendar,
    DatePicker,
  },
  props: {
    isExpanded: Boolean,
    dataOfForm: Object,
    userTriedToConfirm: Boolean,
    initialDate: propOfType<Date | null>(() => null),
    antelacionLAP: propOfType<number>(30),
    metadata: propOfType<Metadata>(),
    addresses: propOfType<AddressOfForm[]>(() => []),
    keepOpen: propOfType<boolean>(false),
    scrollableContainerId: propOfType<string | null>(null),
  },
  created() {
  },
  mounted() {
    if (this.initialDate) {
      this.time = this.initialDate.toLocaleTimeString('es-AR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
      [this.date] = this.initialDate.toISOString().split('T');
    }
  },
  data() {
    return {
      time: '',
      date: '',
      isModal: false,
      subtitleClass: 'poppins font-semibold text-gray-500 text-xs',
    };
  },
  computed: {
    placeholderColor(): string {
      return this.hasDate ? 'text-gray-400' : 'text-accent-500';
    },
    headerText(): string {
      return `${this.dateParsed} · ${this.timeParsed} hs`;
    },
    dateParsed(): string {
      return this.initialDate.toLocaleDateString('es-AR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'America/Argentina/Buenos_Aires',
      });
    },
    timeParsed(): string {
      return this.initialDate.toLocaleTimeString('es-AR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
    },
    getDateInstance(): Date | null {
      if (!this.date || !this.time) return null;
      const date = new Date(`${this.date}T${this.time}`);
      return date;
    },
    errorMessage(): string {
      const dateInstance = this.getDateInstance;
      if (dateInstance && dateInstance < new Date()) {
        return 'Seleccione una fecha valida';
      }
      if (dateInstance === null) {
        return 'Seleccione una fecha y hora';
      }
      return '';
    },
    error(): string {
      if (!this.userTriedToConfirm) return '';
      return this.errorMessage;
    },
    filteredAddresses(): AddressOfForm[] {
      return this.addresses.slice(1, -1).filter((adds) => adds);
    },
    checkExpanded(): boolean {
      return this.isExpanded;
    },
    toWatch(): any[] {
      return [this.date, this.time];
    },
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
    hasDate(): boolean {
      return this.date !== '' && this.time !== '';
    },
  },
  watch: {
    toWatch() {
      this.updateDateOnForm();
    },
  },
  methods: {
    updateDateOnForm() {
      apply(this, (data) => {
        data.date = this.getDateInstance;
      });
    },
    parseDate(date: string): string {
      const original = date;
      const year = original.substr(2, 2);
      const month = original.substr(5, 2);
      const day = original.substr(8, 2);
      return `${day}/${month}/${year}`;
    },
    onHeaderClick() {
      if (this.isExpanded) {
        this.$emit('close');
      } else {
        this.$emit('open');
      }
    },
  },
});
