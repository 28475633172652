
import { VueConstructor } from 'vue';
import {
  ServicioCarpoolDisponible,
} from '@/models/taaxii.com/taaxiiServicioCarpoolDisponible';
import { patch } from '@/services/http';
import userMsg from '@/services/userMsg';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import { getIdPersona } from '@/models/accounts/GeneralAccount';
import {
  SolicitudSuccessfullyBundle,
  AgregarPaxCarpoolReq,
  ParadaCarpool,
  PasajeroCarpool,
} from '@/models/requests-and-responses/sgv';
import { PaxOfForm } from '@/models/Person';
import { propOfType } from '@/services/utils';
import Shared, {
  AddressOfForm,
  Section,
} from '../indexForm.vue';

import ServiceSection from '../sections/service/CarpoolService.vue';
import WhenSection from '../sections/when/RecorridoFijoWhen.vue';
import OriginSection from '../sections/address/Origin.vue';
import DestinationSection from '../sections/address/Destination.vue';
import CecoSection from '../sections/ceco/indexCeco.vue';
import PassengersSection from '../sections/passengers/CarpoolPassengers.vue';
import TripPreview from '../TripPreview.vue';

export function data() {
  return {
    type: 'RECORRIDOFIJO' as const, // Utilizado para conocer el tipo de la data.
    addresses: [] as AddressOfForm[],
    passengers: [] as PaxOfForm[],
    service: null as ServicioCarpoolDisponible | null,
    recorrido: [] as AddressOfForm[],
    date: null as Date | null, // null = Lo antes posible.
    dateRegreso: null as Date | null, // null = Sin viaje de regreso.
    dateOption: 0 as number,
    finalDataResponse: null as SolicitudSuccessfullyBundle | null,
    finalDataResponseRegreso: null as SolicitudSuccessfullyBundle | null,
    days: [] as number[],
    daysRegreso: [] as number[],
    typeOfTrip: 0 as number,
    paxError: false as boolean,
    dateError: false as boolean,
    datosDeArribo: '' as string,
    userTriedToConfirm: false,
    idCeCo: null as number | null,
    observacion: '',
    showTripPreview: false,
  };
}

export default Shared.extend({
  components: {
    OriginSection,
    DestinationSection,
    PassengersSection,
    WhenSection,
    CecoSection,
    ServiceSection,
    TripPreview,
  },
  props: {
    timeEstimation: propOfType<number>(),
  },
  data,
  computed: {
    buttonText() {
      if (!this.showTripPreview) return 'Continuar';
      return 'Pedir';
    },
    buttonTitle() {
      if (this.showTripPreview) {
        return 'Revisá los datos ingresados antes de pedir';
      }
      return '';
    },
    sections(): Section[] {
      if (this.showTripPreview) {
        return [{ component: TripPreview, steps: ['tripPreview'] }];
      }
      return [
        { component: OriginSection, steps: ['origin1', 'origin2'] },
        { component: DestinationSection, steps: ['destination'] },
        { component: WhenSection, steps: ['when3'] },
        { component: PassengersSection, steps: ['passengers1', 'passengers2'] },
        { component: CecoSection, steps: ['ceco'] },
        { component: ServiceSection, steps: ['service1', 'service2'] },
      ];
    },
  },
  watch: {
    addresses() {
      this.service = null;
    },
    services() {
      this.service = null;
    },
    showTripPreview() {
      this.error = '';
    },
    service() {
      if (!this.service) this.setMapAddresses([]);
      else {
        this.recorrido = this.service?.recorrido.map((parada) => ({
          street: parada.parada.direccion,
          lat: parada.parada.lat,
          lon: parada.parada.lon,
          tag: this.setTag(parada.secuencia),
          hora: new Date(parada.fechaHora).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', hour12: false }),
        })) as AddressOfForm[] || [];
        this.setMapAddresses(this.recorrido);
        this.passengers = [];
      }
    },
  },
  methods: {
    async onConfirm() {
      this.expandedSection = -1;
      this.userTriedToConfirm = true;

      if (!this.addresses.length || !this.passengers.length) {
        return;
      }

      if (!this.service) {
        return;
      }

      if (!this.idCeCo || !this.selectedAccount) {
        // Nunca debería llegar a este punto.
        this.error = 'Error fatal.';
        return;
      }

      if (this.dateError || this.paxError) {
        return;
      }

      if (!this.showTripPreview) {
        this.showTripPreview = true;
        return;
      }

      const paradas = this.recorrido.map((adds) => ({
        dir: {
          direccion: adds?.street,
          lat: adds?.lat,
          lon: adds?.lon,
        },
        ascensos: this.mapPaxToIndex(adds?.ascensos || []),
        descensos: this.mapPaxToIndex(adds?.descensos || []),
      })) as ParadaCarpool[];

      const pasajes = this.passengers.map(({
        nombre, tel, idPersona, idCeco,
      }) => {
        const pax = {
          idPersona,
          idCeco: idCeco || this.idCeCo,
          nombre,
          tel,
        } as PasajeroCarpool;

        return pax;
      }) as PasajeroCarpool[];

      const req: AgregarPaxCarpoolReq = {
        idViaje: this.service.idViaje,
        idCarpool: this.service.idCarpool,
        paradas,
        pasajes,
      };

      this.reservating = true;
      this.error = '';

      this.finalDataResponse = await this.makeRequest(req);

      if (this.error) {
        return;
      }
      this.exit(this.$data);
    },
    onExit() {
      if (this.showTripPreview) {
        this.showTripPreview = false;
      } else {
        this.exit(null);
      }
    },
    makeRequest(req : AgregarPaxCarpoolReq) : Promise<SolicitudSuccessfullyBundle | null> {
      return patch('$sgv/rest/solicitudes/carpool', req)
        .then((res) => {
          const response = res.data;
          return response;
        })
        .catch((error) => {
          this.error = userMsg.getError(errorCodeFromAxiosError(error));
          return null;
        })
        .finally(() => {
          this.reservating = false;
        });
    },

    propsOf(section: VueConstructor) {
      switch (section) {
        case OriginSection:
          return {
            addressIndex: 0,
            initialAddress: this.addresses[0],
          };
        case DestinationSection:
          return {
            addressIndex: 1,
            initialAddress: this.addresses[1],
          };
        case PassengersSection:
          return {
            addresses: this.recorrido,
            passengers: this.passengers,
            service: this.service,
          };
        case WhenSection:
          return {
            timeEstimation: this.timeEstimation,
            addresses: this.addresses,
            initialOption: this.dateOption,
            initialDate: this.date,
          };
        case CecoSection:
          return { idCeco: this.idCeCo };
        case ServiceSection:
          return {
            addresses: this.addresses,
            date: this.date,
            idCliente: this.idCliente,
            isLoAntesPosible: this.date === null,
            service: this.service,
          };
        case TripPreview:
          return {
            distanceKm: this.distanceKm,
            addresses: this.recorrido,
            date: this.date,
            service: this.service,
            type: this.type,
          };
        default:
          return {};
      }
    },
    mapPaxToIndex(passengers: PaxOfForm[]): number[] {
      return passengers.map((pax) => this.passengers.findIndex((pax2) => pax2.id === pax.id));
    },
    setTag(val: number): string {
      if (!this.service) return '';
      if (val === 1) return 'origen';
      if (val === this.service.recorrido.length) return 'destino';
      return `parada ${val - 1}`;
    },
  },
});
