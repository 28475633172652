














































import Vue from 'vue';
import {
  capitalizeText, getDetailsPaxList, propOfType, propOfTypeArrayOf,
} from '@/services/utils';
import { SolicitudParada, SolicitudPasaje } from '@/models/ExtrasData';
import { PassengerModel } from '@/models/requests-and-responses/seguimiento';
import Header from '@/modules/site/pages/dashboard/mis-pedidos/details/components/Header.vue';
import { HeaderData } from '@/models/Pedido';
import { patch } from '@/services/http';
import { QuitarPasajeRq } from '@/models/requests-and-responses/sgv';
import { mapStateTyped } from '@/services/store/utils';
import PaxItem from './PaxItem.vue';

export default Vue.extend({
  props: {
    pasajes: propOfTypeArrayOf<SolicitudPasaje>(),
    paradas: propOfTypeArrayOf<SolicitudParada>(),
    cliente: propOfType<string>(),
    servicio: propOfType<string>(),
    fechaHoraSalida: propOfType<Date>(),
    idPedido: propOfType<number>(),
  },
  components: {
    PaxItem,
    Header,
  },
  data() {
    return {
      paxCount: 0,
      loading: false,
      paxToBeRemoved: [] as QuitarPasajeRq[],
    };
  },
  computed: {
    quitarLabel(): string {
      if (this.paxCount === 1) return 'Quitar 1 pasajero';
      if (this.paxCount > 1) return `Quitar ${this.paxCount} pasajeros`;
      return 'Quitar';
    },
    disabled(): boolean {
      return this.paxCount === 0 || this.loading;
    },
    passengers(): PassengerModel[] {
      return getDetailsPaxList(this.pasajes, this.paradas);
    },
    headerData(): HeaderData {
      return {
        pedidoId: this.pasajes[0].idSolicitud,
        cliente: capitalizeText(this.cliente),
        servicio: `Servicio ${this.servicio}`,
        fechaHoraSalida: this.fechaHoraSalida,
      };
    },
  },
  methods: {
    onConfirm() {
      this.loading = true;
      patch('/pedidos/carpool/quitar',
        this.paxToBeRemoved)
        .then(() => {
          this.$toast.success('Pasajeros quitados con éxito');
          this.$emit('updatePedido');
        })
        .catch(() => {
          this.$toast.error('Ocurrió un error al quitar pasajeros');
        })
        .finally(() => {
          this.loading = false;
          this.$emit('close');
        });
    },
    paxToBeRemovedHandler(selected: boolean, idPasaje: number[]) {
      if (selected) {
        this.paxCount += 1;
        idPasaje.forEach((id) => {
          this.paxToBeRemoved.push({
            idPasaje: id,
            idPedido: this.idPedido,
            username: this.pasajes.find((pax) => pax.idPasaje === id)
              ?.pasajero?.username || undefined,
          });
        });
      } else {
        this.paxCount -= 1;
        this.paxToBeRemoved = this.paxToBeRemoved.filter((pax) => !idPasaje.includes(pax.idPasaje));
      }
    },
  },
});
