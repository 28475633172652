

























































import Vue from 'vue';
import {
  capitalizeText, getDetailsPaxList, propOfType, propOfTypeArrayOf,
} from '@/services/utils';
import { SolicitudParada, SolicitudPasaje } from '@/models/ExtrasData';
import { PassengerModel } from '@/models/requests-and-responses/seguimiento';
import Header from '@/modules/site/pages/dashboard/mis-pedidos/details/components/Header.vue';
import { HeaderData } from '@/models/Pedido';
import { AgregarPaxCarpoolReq, Parada, ParadaCarpool } from '@/models/requests-and-responses/sgv';
import { patch } from '@/services/http';
import PersonSearchBar, { Pax } from '@/modules/site/pages/dashboard/nuevo-pedido/form/sections/person/PersonSearchBar.vue';

function getEmptyPerson(): Pax {
  return {
    nameAndLastName: '',
    phone: '',
    phoneIsValid: false,
    person: null,
  } as Pax;
}

export default Vue.extend({
  props: {
    pasajes: propOfTypeArrayOf<SolicitudPasaje>(),
    paradas: propOfTypeArrayOf<SolicitudParada>(),
    cliente: propOfType<string>(),
    servicio: propOfType<string>(),
    fechaHoraSalida: propOfType<Date>(),
    idViaje: propOfType<number>(),
    idCarpool: propOfType<number>(),
  },
  components: {
    Header,
    PersonSearchBar,
  },
  data() {
    return {
      paxAmount: 1,
      loading: false,
      selectedPerson: getEmptyPerson(),
    };
  },
  computed: {
    agregarLabel(): string {
      if (this.paxAmount === 1) return 'Agregar 1 pasajero';
      if (this.paxAmount > 1) return `Agregar ${this.paxAmount} pasajeros`;
      return 'Agregar';
    },
    disabled(): boolean {
      return this.paxAmount === 0
      || this.selectedPerson.nameAndLastName === ''
      || this.loading;
    },
    passengers(): PassengerModel[] {
      return getDetailsPaxList(this.pasajes, this.paradas);
    },
    headerData(): HeaderData {
      return {
        pedidoId: this.pasajes[0].idSolicitud,
        cliente: capitalizeText(this.cliente),
        servicio: `Servicio ${this.servicio}`,
        fechaHoraSalida: this.fechaHoraSalida,
      };
    },
    availableSeats(): number {
      return this.pasajes.filter((p) => p.tipo === 'ASIENTO VACÍO').length;
    },
    initialData(): Pax {
      const {
        nameAndLastName,
        phone,
        phoneIsValid,
        person,
      } = this.selectedPerson;
      return {
        nameAndLastName,
        phone,
        phoneIsValid,
        person,
      };
    },
  },
  methods: {
    onConfirm() {
      this.loading = true;
      const paradas = this.paradas.map((p) => ({
        dir: {
          direccion: p.dir.direccion,
          lat: p.dir.lat,
          lon: p.dir.lon,
        },
        ascensos: [],
        descensos: [],
      })) as ParadaCarpool[];
      this.setAscensosDescensos(paradas);
      const req: AgregarPaxCarpoolReq = {
        idViaje: this.idViaje,
        idCarpool: this.idCarpool,
        paradas,
        pasajes: Array.from({ length: this.paxAmount }, () => ({
          nombre: `${this.selectedPerson.nameAndLastName}`,
          tel: this.selectedPerson.phone,
          idPersona: this.selectedPerson.person ? this.selectedPerson.person.personId : undefined,
          idCeco: this.selectedPerson.person ? this.selectedPerson.person.cecoPreferido : undefined,
        })),
      };
      patch('$sgv/rest/solicitudes/carpool', req)
        .then(() => {
          this.$toast.success('Pasajeros agregados con éxito');
          this.$emit('updatePedido');
          this.$emit('close');
        })
        .catch(() => {
          this.$toast.error('Ocurrió un error al agregar pasajeros');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sumarPax() {
      if (this.paxAmount < this.availableSeats) {
        this.paxAmount += 1;
      }
    },
    restarPax() {
      if (this.paxAmount > 1) {
        this.paxAmount -= 1;
      }
    },
    setAscensosDescensos(stops: ParadaCarpool[]) {
      stops[0].ascensos = Array.from({ length: this.paxAmount }, (_, i) => i);
      stops[stops.length - 1].descensos = Array.from({ length: this.paxAmount }, (_, i) => i);
    },
    updatePerson(newPerson: Pax) {
      this.selectedPerson = newPerson;
    },
  },
});
