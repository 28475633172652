
















































import GenericPerson from '@/models/taaxii.com/taaxiiGenericPerson';
import { capitalizeText, propOfType, propOfTypeArrayOf } from '@/services/utils';
import IconCheck20 from '@/components/icons/IconCheck20.vue';
import Vue from 'vue';

export default Vue.extend({
  components: { IconCheck20 },
  props: {
    person: propOfType<GenericPerson>(),
    paradas: propOfType<string[] | null>(null),
    from: String,
    to: String,
    idPasaje: propOfTypeArrayOf<number>(),
  },
  data() {
    return {
      selected: false,
      paradasExpanded: false,
    };
  },
  computed: {
    name(): string {
      return this.formatName(this.person);
    },
    tieneParadas(): boolean {
      return this.paradas.length > 2;
    },
    showParadas(): string {
      if ((this.paradas.length - 2) === 1) {
        return '+1 parada';
      } return `+${(this.paradas.length - 2)} paradas`;
    },
    soloParadas(): string[] {
      return this.paradas?.slice(1, this.paradas.length - 1) || [];
    },
    destino(): string {
      return this.paradas[this.paradas.length - 1];
    },
  },
  methods: {
    formatDirection(direccion: string): string {
      return direccion.split(',', 3).join(',');
    },
    formatName(person: GenericPerson): string {
      if (!person.lastName) return capitalizeText(person.firstName);
      return capitalizeText(`${person?.lastName}, ${person?.firstName}`);
    },
    expandParadas(): void {
      this.paradasExpanded = true;
    },
    collapseParadas(): void {
      this.paradasExpanded = false;
    },
    addToRemoveList() {
      this.selected = !this.selected;
      this.$emit('select', this.selected, this.idPasaje);
    },
  },
});
