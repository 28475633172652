

































import Vue from 'vue';
import IconInfo from '@/components/icons/IconInfo.vue';
import { propOfType, toTwoDecimals } from '@/services/utils';
import { Importe } from '@/models/taaxii.com/taaxiiImporte';
import { CostosInfo } from '@/models/requests-and-responses/sgv';

export default Vue.extend({
  components: {
    IconInfo,
  },
  props: {
    sharedData: { type: Object, default: () => null },
    costs: propOfType<CostosInfo | null>(null),
  },
  computed: {
    detalles(): Importe[] | null {
      const order = ['kilometros', 'espera', 'otros'];

      if (this.costs) {
        const detalles = [...this.costs.detalle];

        detalles.sort((a: Importe, b: Importe): number => {
          const aIndex = order.indexOf(a.concepto);
          const bIndex = order.indexOf(b.concepto);

          if (aIndex === -1 && bIndex === -1) return 0;

          if (aIndex === -1) return 1;
          if (bIndex === -1) return -1;

          return aIndex - bIndex;
        });

        return detalles;
      }
      return null;
    },
  },
  methods: {
    getPesos(value): string {
      return `${toTwoDecimals(value)} $`;
    },
    getCantidad(value): string {
      return value.cantidad ? `${toTwoDecimals(value.cantidad)} ${value.unidad}` : '';
    },
  },
});
