















































import Vue from 'vue';
import IconEmployeeGroup from '@/components/icons/IconEmployeeGroup.vue';
import IconPencilAlt20 from '@/components/icons/IconPencilAlt20.vue';
import { PaxOfForm } from '@/models/Person';
import { propOfType } from '@/services/utils';
import { ServicioCarpoolDisponible } from '@/models/taaxii.com/taaxiiServicioCarpoolDisponible';
import CarpoolEditPaxModal from './CarpoolEditPaxModal.vue';
import SectionLayout from '../SectionLayout.vue';
import { AddressOfForm, apply } from '../../indexForm.vue';
import { Metadata } from '../../../CreateWeb.vue';

export function deletePax(id: number, addresses: AddressOfForm[], passengers: PaxOfForm[]) {
  const ascensos = addresses
    .find((adds) => adds?.ascensos?.some((pax) => pax.id === id))?.ascensos;
  const descensos = addresses
    .find((adds) => adds?.descensos?.some((pax) => pax.id === id))?.descensos;
  if (ascensos && descensos) {
    const iPax = findPaxById(passengers, id);
    const iOrigen = findPaxById(ascensos, id);
    const iDestino = findPaxById(descensos, id);
    if (iPax > -1 && iOrigen > -1 && iDestino > -1) {
      passengers.splice(iPax, 1);
      ascensos.splice(iOrigen, 1);
      descensos.splice(iDestino, 1);
    }
  }
}

export function findPaxById(passengers: PaxOfForm[], id: number): number {
  return passengers.findIndex((pax) => pax.id === id);
}

export default Vue.extend({
  components: {
    SectionLayout,
    CarpoolEditPaxModal,
    IconEmployeeGroup,
    IconPencilAlt20,
  },
  props: {
    userTriedToConfirm: Boolean,
    addresses: propOfType<AddressOfForm[]>(() => []),
    passengers: propOfType<PaxOfForm[]>(() => []),
    metadata: propOfType<Metadata>(),
    service: propOfType<ServicioCarpoolDisponible | null>(null),
  },
  mounted() {
    this.copyRecorridoInAddresses();
    this.copyPax = [...this.passengers];
  },
  data() {
    return {
      editPaxs: false,
      copyAddresses: [] as AddressOfForm[],
      copyPax: [] as PaxOfForm[],
    };
  },
  watch: {
    service() {
      this.copyRecorridoInAddresses();
    },
    editPaxs() {
      this.copyRecorridoInAddresses();
      this.copyPax = [...this.passengers];
    },
    addresses() {
      this.copyRecorridoInAddresses();
    },
    passengers() {
      this.copyPax = [...this.passengers];
    },
    errorMessage() {
      apply(this, (data) => {
        data.paxError = Boolean(this.errorMessage);
      });
    },
  },
  computed: {
    headerText(): string {
      if (!this.passengers.length) return '';

      const paxs = this.passengers.reduce((listado, pax) => {
        if (!listado.includes(pax.nombre)) {
          listado.push(pax.nombre);
        }
        return listado;
      }, [] as string[]).length;

      return paxs === 1 ? `${paxs} pasajero` : `${paxs} pasajeros`;
    },
    errorMessage(): string {
      if (!this.passengers.length) return 'No hay pasajeros';
      return '';
    },
    error(): string {
      if (!this.userTriedToConfirm) return '';
      return this.errorMessage;
    },
    recorridoSelected(): boolean {
      return this.service !== null;
    },
  },
  methods: {
    copyRecorridoInAddresses() {
      /* Duplico los valores de esta forma porque si no los cambios en
        copyAdresses afecta el array original */
      this.copyAddresses = this.addresses.map((originalAddress) => {
        if (!originalAddress) return undefined;
        const newAddress = { ...originalAddress };

        if (originalAddress.ascensos) {
          newAddress.ascensos = [...originalAddress.ascensos as PaxOfForm[]];
        } else {
          newAddress.ascensos = [];
        }

        if (originalAddress.descensos) {
          newAddress.descensos = [...originalAddress.descensos as PaxOfForm[]];
        } else {
          newAddress.descensos = [];
        }

        return newAddress;
      });
    },
    onSave() {
      apply(this, (data) => {
        if ('passengers' in data && 'addresses' in data && 'recorrido' in data) {
          data.passengers = [...this.copyPax];
          data.recorrido = [...this.copyAddresses];
          data.paxError = Boolean(this.errorMessage);
        }
      });
      this.editPaxs = false;
    },
    onClose() {
      this.editPaxs = false;
    },
    openModal() {
      if (this.recorridoSelected) this.editPaxs = true;
    },
  },
});

