var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.hidden)?_c('div',{staticClass:"flex flex-col"},[_c('button',{ref:"sectionButton",staticClass:"flex flex-row h-10 items-center bg-transparent select-none\n      pl-4 rounded-lg focus:bg-accent-1 mx-0.5 mt-0.5",class:{ 'bg-accent-1 outline-none rounded-b-none mt-0': _vm.isExpanded,
      'cursor-default' : _vm.defaultCursor,
      'cursor-pointer' : !_vm.defaultCursor },attrs:{"id":("section-" + _vm.id)},on:{"click":function($event){return _vm.$emit('onHeaderClick')}}},[(_vm.loading)?_c('BaseButtonSpinner',{staticClass:"mr-4 w-4 h-4 flex-none"}):_c('div',{staticClass:"mr-4 w-5 h-5 flex-none text-gray-400"},[_vm._t("icon")],2),_vm._t("header",[_vm._v("Please, define a header.")]),_c('div',{staticClass:"ml-auto mr-3 relative",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('onEndIconClick')}}},[_vm._t("endIconMenu",[_c('div',{staticClass:"rounded-full w-4 h-4 group"},[(_vm.error)?_c('BaseErrorIcon',{attrs:{"error":_vm.error,"iconClass":"inset-y-0.5"}}):_c('div',{staticClass:"\n              text-gray-400\n              m-auto\n              w-3\n              h-3\n              flex-none\n              transform\n              absolute\n              left-0\n              right-0\n              top-0\n              bottom-0\n            ",class:{
              '-rotate-90': _vm.isExpanded && !_vm.error,
              'rotate-90': !_vm.isExpanded && !_vm.error,
              'transition-transform': _vm.shouldAnimate,
              'duration-100': _vm.shouldAnimate,
            }},[(_vm.removable && !_vm.hiddenEndIcon)?_c('IconClose',{staticClass:"w-3 h-3 absolute left-0 right-0 top-0 bottom-0",attrs:{"stroke-width":"2.1"}}):(!_vm.hiddenEndIcon)?_c('IconChevronDownSection',{staticClass:"w-3 h-3 absolute left-0 right-0 top-0 bottom-0"}):_vm._e()],1)],1)])],2)],2),_c('div',{staticClass:"py-2 transition-h transform duration-200 bg-accent-1 rounded-b-lg",class:{ '-translate-y-full': !_vm.isExpanded, hidden: !_vm.isExpanded }},[_vm._t("default")],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }