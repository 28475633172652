








































import Vue from 'vue';
import { capitalizeText, formatNumber, propOfType } from '@/services/utils';
import { ServicioCarpoolDisponible } from '@/models/taaxii.com/taaxiiServicioCarpoolDisponible';

export default Vue.extend({
  components: {
  },
  props: {
    selected: Boolean,
    service: propOfType<ServicioCarpoolDisponible>(),
    description: String,
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    idTipoVehiculo(): number {
      return this.service.idTipoVehiculo;
    },
    name(): string {
      return capitalizeText(this.service.tipoVehiculo);
    },
    precio(): string {
      return `$${formatNumber(this.service.precotizacion)}`;
    },
    horaSalida(): string {
      const date = new Date(this.service.recorrido[0].fechaHora);
      return date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', hour12: false });
    },
    horaLlegada(): string {
      const date = new Date(this.service.recorrido[this.service.recorrido.length - 1].fechaHora);
      return date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', hour12: false });
    },
    hayParadas(): boolean {
      return this.service.recorrido.length > 2;
    },
    cantParadas(): number {
      return this.service.recorrido.length - 2;
    },
    origen(): string {
      return this.service.recorrido[0].parada.direccion.split(',', 1).toString();
    },
    destino(): string {
      return this.service.recorrido[this.service.recorrido.length - 1].parada.direccion.split(',', 1).toString();
    },
    showParadas(): string {
      if (this.cantParadas === 1) {
        return '+1 parada';
      } return `+${this.cantParadas} paradas`;
    },
    asientosDisponibles(): string {
      return `Libres: ${this.service.asientosDisponibles}`;
    },
  },
  methods: {
  },
});
