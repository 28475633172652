

















































































import { propOfType } from '@/services/utils';
import Vue from 'vue';
import { PaxOfForm } from '@/models/Person';
import store from '@/services/store';
import { GeneralAccount } from '@/models/accounts/GeneralAccount';
import { mapStateTyped } from '@/services/store/utils';
import { AddressOfForm } from '@/modules/site/pages/dashboard/nuevo-pedido/form/indexForm.vue';
import { deletePax } from '@/modules/site/pages/dashboard/nuevo-pedido/form/sections/passengers/indexPassengers.vue';
import PersonSearchBar, { Pax } from '@/modules/site/pages/dashboard/nuevo-pedido/form/sections/person/PersonSearchBar.vue';
import RouteTimeline from '@/modules/site/pages/dashboard/nuevo-pedido/form/RouteTimeline.vue';
import { SolicitudParada, SolicitudPasaje } from '@/models/ExtrasData';
import { AgregarPaxCarpoolReq, ParadaCarpool, PasajeroCarpool } from '@/models/requests-and-responses/sgv';
import { patch } from '@/services/http';

function getEmptyPerson(): Pax {
  return {
    nameAndLastName: '',
    phone: '',
    phoneIsValid: false,
    person: null,
  } as Pax;
}

function convertSolicitudParadaToAddressOfForms(paradas: SolicitudParada[]): AddressOfForm[] {
  return paradas.map((parada, index) => {
    let tag = '';

    if (index === 0) {
      tag = 'origen';
    } else if (index === paradas.length - 1) {
      tag = 'destino';
    } else {
      tag = `parada ${index}`;
    }

    // Formatear la hora en "HH:MM hs"
    const fecha = new Date(parada.fechaHora);
    const hora = fecha.getHours().toString().padStart(2, '0');
    const minutos = fecha.getMinutes().toString().padStart(2, '0');
    const horaFormateada = `${hora}:${minutos}`;

    return {
      street: parada.dir.direccion,
      alias: parada.dir.alias,
      hora: horaFormateada,
      isTerminal: false,
      tag,
      id: parada.idParada,
      lat: parada.dir.lat,
      lon: parada.dir.lon,
      ascensos: [] as PaxOfForm[],
      descensos: [] as PaxOfForm[],
    };
  });
}

export default Vue.extend({
  props: {
    paradas: propOfType<SolicitudParada[]>(),
    pasajes: propOfType<SolicitudPasaje[]>(),
    idViaje: propOfType<number>(),
    idCarpool: propOfType<number>(),
  },
  components: {
    PersonSearchBar,
    RouteTimeline,
  },
  data() {
    return {
      addresses: [] as AddressOfForm[],
      passengers: [] as PaxOfForm[],
      selectedPerson: getEmptyPerson(),
      origen: null as number | null,
      destino: null as number | null,
      currentId: 0,
      triedToAddPax: false,
      triedToConfirm: false,
      wrongStopOrder: false,
      isLoading: false,
    };
  },
  mounted() {
    this.addresses = convertSolicitudParadaToAddressOfForms(this.paradas);
  },
  watch: {
    origen() {
      this.wrongStopOrder = false;
    },
    destino() {
      this.wrongStopOrder = false;
    },
    isPersonComplete() {
      this.triedToConfirm = false;
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    updatePerson(newPerson: Pax) {
      this.selectedPerson = newPerson;
    },
    findPaxById(passengers: PaxOfForm[], id: number): number {
      return passengers.findIndex((pax) => pax.id === id);
    },
    addPax() {
      this.triedToAddPax = true;
      this.wrongStopOrder = false;

      if (this.errorOnPax || this.origen === null || this.destino === null) {
        return;
      }

      const origen = this.addresses[this.origen]?.ascensos;
      const destino = this.addresses[this.destino]?.descensos;

      if (origen && destino) {
        origen.push(this.paxToPaxOfForm);
        destino.push(this.paxToPaxOfForm);

        this.passengers.push(this.paxToPaxOfForm);
        this.currentId += 1;
        this.selectedPerson = getEmptyPerson();
        this.origen = null;
        this.destino = null;
        this.triedToAddPax = false;
      }
    },
    deletePassenger(id: number) {
      deletePax(id, this.addresses, this.passengers);
    },
    clearPerson() {
      this.selectedPerson = getEmptyPerson();
      this.origen = null;
      this.destino = null;
    },
    copiarSol() {
      const { selectedAccount } = this;
      this.selectedPerson.nameAndLastName = `${this.user?.apellido}, ${this.user?.nombre}`;
      if (selectedAccount) {
        if (selectedAccount.tag === 'particularAccount') {
          this.selectedPerson.phone = selectedAccount.account.phone;
        }
        if (selectedAccount.tag === 'corporateAccount') {
          const { account } = selectedAccount;
          if (account) {
            this.selectedPerson.phone = account.celular || '';
            this.selectedPerson.person = {
              firstname: this.user?.nombre || '-',
              lastname: this.user?.apellido || '-',
              phone: account.celular || '',
              corporateAccountId: account.idCuentaSgv,
              personId: account.idPersona,
              cecoPreferido: account.idCentroDeCostoPreferido || 0,
              centrosDeCostoHabilitados: account.centrosDeCosto,
              formaDePagoPorDefecto: account.formaDePagoPorDefecto,
            };
          }
        }
      }
    },
    onConfirm() {
      this.isLoading = true;
      this.triedToConfirm = true;
      if (this.errorOnConfirm) {
        this.isLoading = false;
        return;
      }
      const paradas = this.addresses.map((adds) => ({
        dir: {
          direccion: adds?.street,
          lat: adds?.lat,
          lon: adds?.lon,
        },
        ascensos: this.mapPaxToIndex(adds?.ascensos || []),
        descensos: this.mapPaxToIndex(adds?.descensos || []),
      })) as ParadaCarpool[];

      const pasajes = this.passengers.map(({
        nombre, tel, idPersona, idCeco,
      }) => {
        const pax = {
          idPersona,
          idCeco: idCeco || undefined,
          nombre,
          tel,
        } as PasajeroCarpool;

        return pax;
      }) as PasajeroCarpool[];

      const req: AgregarPaxCarpoolReq = {
        idViaje: this.idViaje,
        idCarpool: this.idCarpool,
        paradas,
        pasajes,
      };

      patch('$sgv/rest/solicitudes/carpool', req)
        .then((res) => {
          const response = res.data;
          this.$toast.success('Pasajeros agregados con éxito');
          this.$emit('updatePedido');
        })
        .catch(() => {
          this.$toast.error('Ocurrió un error al agregar pasajeros');
        })
        .finally(() => {
          this.isLoading = false;
          this.$emit('close');
        });
    },
    mapPaxToIndex(passengers: PaxOfForm[]): number[] {
      return passengers.map((pax) => this.passengers.findIndex((pax2) => pax2.id === pax.id));
    },
  },
  computed: {
    ...mapStateTyped(['user']),
    selectedAccount(): GeneralAccount | null {
      return store.getters.selectedAccount;
    },
    filteredAddresses(): { value: number, text: string }[] {
      return this.addresses.filter((address) => address)
        .map((address, index) => ({ value: index, text: address?.street || '-' }));
    },
    origenOptions(): { value: number, text: string }[] {
      const index = this.destino === null ? -1 : this.destino;
      return this.filteredAddresses.slice(0, index);
    },
    destinoOptions(): { value: number, text: string }[] {
      const index = this.origen === null ? 1 : this.origen + 1;
      return this.filteredAddresses.slice(index);
    },
    paxToPaxOfForm(): PaxOfForm {
      const pax = this.selectedPerson;
      return {
        id: this.currentId,
        nombre: pax.nameAndLastName,
        tel: pax.phone,
        idPersona: pax.person ? pax.person.personId : undefined,
        idCeco: pax.person ? pax.person.cecoPreferido : undefined,
      } as PaxOfForm;
    },
    // Con esta computed fuerzo que los datos dentro de PersonSearchBar se actualicen siempre
    initialData(): Pax {
      const {
        nameAndLastName,
        phone,
        phoneIsValid,
        person,
      } = this.selectedPerson;
      return {
        nameAndLastName,
        phone,
        phoneIsValid,
        person,
      };
    },
    errorOnPax(): string {
      const errors = [] as string[];
      if (this.wrongStopOrder) {
        return 'El pasajero debe descender antes de volver a ascender';
      }
      if (this.passengers.length >= this.asientosDisponibles) {
        return 'No quedan asientos disponibles';
      }
      if (!this.selectedPerson.nameAndLastName.length) {
        errors.push('nombre');
      }
      if (this.origen === null) {
        errors.push('origen');
      }
      if (this.destino === null) {
        errors.push('destino');
      }

      return errors.length ? `Faltan los siguientes datos: ${errors.join(', ')}` : '';
    },
    errorOnConfirm(): string {
      if (!this.triedToConfirm) return '';
      if (this.isPersonComplete) return 'No terminó de agregar un pasajero';
      if (this.passengers.length === 0) return 'Debe agregar al menos un pasajero';
      return '';
    },
    isPersonComplete(): boolean {
      return Boolean(this.selectedPerson.nameAndLastName
        && this.origen !== null && this.destino !== null);
    },
    asientosDisponibles(): number {
      return this.pasajes.filter((pax) => pax.tipo === 'ASIENTO VACÍO').length;
    },
    confirmLabel(): string {
      if (this.isLoading) return 'Agregando...';
      if (this.passengers.length === 1) return 'Agregar 1 pasajero';
      if (this.passengers.length > 1) return `Agregar ${this.passengers.length} pasajeros`;
      return 'Agregar pasajero';
    },
  },
});

