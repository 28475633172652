













































import GenericPerson from '@/models/taaxii.com/taaxiiGenericPerson';
import { GenericCeCo } from '@/models/accounts/shared';
import { propOfType, capitalizeText } from '@/services/utils';
import Vue from 'vue';
import IconX20 from '@/components/icons/IconX20.vue';

export default Vue.extend({
  components: {
    IconX20,
  },
  props: {
    person: propOfType<GenericPerson>(),
    ceco: propOfType<GenericCeCo>(null),
    obs: propOfType<string>(),
  },
  data() {
    return {
      moreData: false,
      showOtherEmails: false,
      tooltipStyles: {
        top: '0px',
        left: '0px',
      },
    };
  },
  computed: {
    name(): string {
      return this.formatName(this.person);
    },
    email(): string[] {
      return this.person?.email?.split(',') || [];
    },
    cantEmails(): string {
      const endString = this.email.length - 1 === 1 ? ' email' : ' emails';
      return `+${this.email.length - 1 + endString}`;
    },
    firstEmail(): string {
      return this.email[0] || '';
    },
    firstEmailIsTooLong(): boolean {
      const text = this.$refs.firstEmail as HTMLElement;
      return text.scrollWidth > text.offsetWidth;
    },
    phone(): string {
      return this.person?.phone || '';
    },
    costCenter(): string {
      return `(${this.ceco?.alias})` || '';
    },
    observations(): string {
      return this.obs || 'No hay observaciones';
    },
    hasMoreThanOneEmail(): boolean {
      return this.email.length > 1;
    },
  },
  methods: {
    formatName(person: GenericPerson): string {
      if (!person.lastName) return capitalizeText(person.firstName);
      return capitalizeText(`${person?.lastName}, ${person?.firstName}`);
    },
    close() {
      this.$emit('close');
    },
    showTooltip() {
      this.showOtherEmails = true;
      this.calculateTooltipPosition();
      window.addEventListener('scroll', this.calculateTooltipPosition, true);
      window.addEventListener('resize', this.calculateTooltipPosition);
    },
    hideTooltip() {
      this.showOtherEmails = false;
      window.removeEventListener('scroll', this.calculateTooltipPosition, true);
      window.removeEventListener('resize', this.calculateTooltipPosition);
    },
    calculateTooltipPosition() {
      const emailElement = this.$refs.firstEmail as HTMLElement;
      if (emailElement) {
        const rect = emailElement.getBoundingClientRect();
        this.tooltipStyles = {
          top: `${rect.bottom + window.scrollY + 8}px`,
          left: `${rect.left + window.scrollX}px`,
        };
      }
    },
  },
});
