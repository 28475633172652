




















































import Vue from 'vue';

import { Cliente } from '@/models/employees/Cliente';
import { ErrorCode, errorCodeFromAxiosError } from '@/models/ErrorCode';
import { propOfType, validators } from '@/services/utils';
import userMsg from '@/services/userMsg';
import { createEmpleado } from '@/services/api/empleadosApi';
import { CreateEmpleadoRequest } from '@/models/requests-and-responses/empleados';
import { AxiosError } from 'axios';
import { Group } from '@/models/requests-and-responses/grupos';
import { getPermissions, getSelectedAccount } from '@/services/store/state';
import store from '@/services/store';
import EditUserForm, { Model as EditModel, ValidationError, validationErrorToString } from '../shared/EditUserForm.vue';
import ValidatedInputWithTooltip from './ValidatedInputWithTooltip.vue';

/** Reuso el formulario de Editar usuario, encapsulado en `EditUserForm.vue`.
*/

// --- Model

// Modelo de datos que se edita con el formulario usando v-model.

type Model = {
  usuario: string,
  contraseña: string,
  repetirContraseña: string,
} & EditModel;

function newUserModel(): Model {
  return {
    usuario: '',
    contraseña: '',
    repetirContraseña: '',
    nombre: '',
    apellido: '',
    dni: '',
    legajo: '',
    celular: '',
    email: '',
    activo: true,
    permisos: {
      approveCotizaciones: false,
      changeDefaultCeco: true,
      manageEmpleados: false,
      manageGroups: false,
      registerBoxRequest: true,
      registerBusRequest: true,
      registerExpressRequest: true,
      registerGoRequest: true,
      registerStopRequest: true,
      registerCarpoolRequest: true,
      requestExclusiveVehicle: false,
      showAcuerdos: false,
      showClientPedidos: false,
      showCeCoPedidos: false,
      showGroupPedidos: false,
      travel: true,
      showReports: false,
    },
    centrosDeCostoHabilitados: [],
    idCentrosDeCostoAuditados: [],
    idGruposAuditados: [],
    centroDeCostoPreferido: -1,
    idFormaDePagoPorDefecto: null,
    idGrupo: getGroupId(),
  };
}

function modelToCreateEmpleadoRequest(
  model: Model,
  idClientePadre: number,
): CreateEmpleadoRequest {
  return {
    usuario: model.usuario,
    contraseña: model.contraseña,
    nombre: model.nombre,
    apellido: model.apellido,
    dni: model.dni,
    legajo: model.legajo,
    celular: model.celular,
    email: model.email,
    tipoDni: 'AR_DNI',
    idCentroDeCostoPreferido: model.centroDeCostoPreferido,
    idCentrosDeCostoHabilitados: model.centrosDeCostoHabilitados,
    idCentrosDeCostoAuditados: model.idCentrosDeCostoAuditados,
    idGruposAuditados: model.idGruposAuditados,
    activo: model.activo,
    permisos: model.permisos,
    idClientePadre,
    idFormaDePagoPorDefecto: model.idFormaDePagoPorDefecto,
    idGrupo: model.idGrupo || null,
  };
}

function getGroupId(): number | undefined {
  const permisos = getPermissions(store.state);
  const account = getSelectedAccount(store.state);

  if (!permisos?.manageEmpleados && permisos?.manageGroups) {
    return account?.tag === 'corporateAccount'
      ? account.account.grupo.id : undefined;
  }

  return undefined;
}
// ---  Validation Error Messages

type ValidationErrorMessages = {
  usuario: string | null,
  contraseña: string | null,
  repetirContraseña: string | null,
}

function validationErrorMessages(model: Model): ValidationErrorMessages {
  return {
    usuario: validators.isValidUser(model.usuario)
      ? null
      : userMsg.getError('username_invalid'),
    contraseña: validators.isValidPass(model.contraseña)
      ? null
      : userMsg.getError('password_invalid'),
    repetirContraseña: model.contraseña === model.repetirContraseña
      ? null
      : userMsg.getError('password_not_equal'),
  };
}

function extraFieldsValidationError(
  validationError: ValidationErrorMessages,
): ValidationError {
  if (
    validationError.usuario === null
      && validationError.contraseña === null
      && validationError.repetirContraseña === null
  ) {
    return { tag: 'noError' };
  }

  return {
    tag: 'errors',
    fields: Object.entries(validationError)
      .filter(([_, value]) => value !== null)
      .map(([key, _]) => getFieldName(key as keyof ValidationErrorMessages)),
  };
}

// eslint-disable-next-line consistent-return
function getFieldName(key: keyof ValidationErrorMessages): string {
  // eslint-disable-next-line default-case
  switch (key) {
    case 'usuario':
    case 'contraseña':
      return key;
    case 'repetirContraseña':
      return 'repetir contraseña';
  }
}

function combineValidationErrors(a: ValidationError, b: ValidationError): ValidationError {
  if (a.tag === 'noError') {
    return b;
  }

  if (b.tag === 'noError') {
    return a;
  }

  return {
    tag: 'errors',
    fields: [
      ...a.fields,
      ...b.fields,
    ],
  };
}

// --- RequestStatus

// Estado de la llamada a la API

type RequestStatus =
  | { status: 'notAsked' }
  | { status: 'loading' }
  | { status: 'error', error: ErrorCode }

function notAsked(): RequestStatus {
  return { status: 'notAsked' };
}

export default Vue.extend({
  props: {
    cliente: propOfType<Cliente>(),
    groups: propOfType<Group[]>(),
    onClose: propOfType<Function>(() => () => {}),
    onSave: propOfType<Function>(() => () => {}),
    idClientePadre: propOfType<number>(-1),
  },
  components: {
    EditUserForm, ValidatedInputWithTooltip,
  },
  data() {
    const model = newUserModel();

    return {
      model,
      save: notAsked(),
      showValidationError: false,
      showErrorToast: true,
      editUserValidationError: { tag: 'noError' } as ValidationError,
      extraFieldsValidationError: extraFieldsValidationError(validationErrorMessages(model)),
    };
  },
  computed: {
    validationMessage(): string | null {
      if (!this.showValidationError) {
        return null;
      }

      return validationErrorToString(this.validationError);
    },
    validationErrorMessages(): ValidationErrorMessages {
      return validationErrorMessages(this.model);
    },
    shownValidationErrorMessages(): ValidationErrorMessages {
      if (this.showValidationError) {
        return this.validationErrorMessages;
      }

      return {
        usuario: null,
        contraseña: null,
        repetirContraseña: null,
      };
    },
    validationError(): ValidationError {
      return combineValidationErrors(this.extraFieldsValidationError, this.editUserValidationError);
    },
    usernameTooltip(): string {
      return userMsg.getError('username_invalid');
    },
    passwordTooltip(): string {
      return userMsg.getError('password_invalid');
    },
    errorMessage(): string | null {
      return this.save.status === 'error' ? this.errorMsg(this.save.error) : this.validationMessage;
    },
    isGroupAdminOnly(): boolean {
      const permisos = getPermissions(store.state);
      return Boolean(!permisos?.manageEmpleados && permisos?.manageGroups);
    },
  },
  watch: {
    model() {
      // Recalcula los errores de validación cada vez que cambia el modelo.
      this.extraFieldsValidationError = extraFieldsValidationError(this.validationErrorMessages);
      if (this.validationError.tag === 'noError') {
        this.showValidationError = false;
      }
    },
    errorMessage(value, oldValue) {
      // Muestra un $toast cada vez que hay un error.
      if (value !== null && value !== oldValue && this.showErrorToast) {
        this.$toast.error(value);
        this.showErrorToast = false;
      }
      if (value === null) {
        this.showErrorToast = true;
      }
    },
  },
  methods: {
    onEditUserValidationErrorChange(validationError: ValidationError) {
      this.editUserValidationError = validationError;
    },
    onCreateButtonClick() {
      if (this.validationError.tag === 'errors') {
        if (this.errorMessage) {
          this.$toast.error(this.errorMessage);
        }
        this.showValidationError = true;
        return;
      }

      if (this.save.status === 'loading') {
        return;
      }

      this.save = { status: 'loading' };

      if (this.model.idGrupo === undefined) {
        this.model.idGrupo = -1;
      }

      const { permisos } = this.model;

      if (!permisos.showReports || permisos.showClientPedidos) {
        this.model.idCentrosDeCostoAuditados = [];
        this.model.idGruposAuditados = [];
      }
      if (permisos.showCeCoPedidos) {
        this.model.idGruposAuditados = [];
      }
      if (permisos.showGroupPedidos) {
        this.model.idCentrosDeCostoAuditados = [];
      }

      const request = modelToCreateEmpleadoRequest(this.model, this.idClientePadre);

      createEmpleado(request)
        .then((response) => {
          this.$toast.success(userMsg.getSuccess('user_created'));
          this.onSave(null);
        })
        .catch((error: AxiosError) => {
          this.save = { status: 'error', error: errorCodeFromAxiosError(error) };
        });
    },
    errorMsg(key: string): string {
      return userMsg.getError(key);
    },
  },
});

