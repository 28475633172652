









































import Vue from 'vue';
import Pedido from '@/models/Pedido';
import IconClose24 from '@/components/icons/IconClose24.vue';
import { mapStateTyped } from '@/services/store/utils';
import { propOfType } from '@/services/utils';
import { api, getHeaders, patch } from '@/services/http';
import { QuitarPasajeRq } from '@/models/requests-and-responses/sgv';
import { eventBus } from '@/main';

export default Vue.extend({
  props: {
    pedido: propOfType<Pedido>(),
    href: String,
  },
  components: {
    IconClose24,
  },
  data() {
    return {
      infoViaje: null as any,
      infoMapa: null as any,
      isViajeLoaded: false,
      isMapaLoaded: false,
      isTimelineLoaded: false,
      stars: 0,
      solicitaRevisar: false,
      loading: false,
      message: '',
      starHint: 0,
      expanded: false,
    };
  },
  methods: {
    onConfirm() {
      this.loading = true;
      if (this.isCarpool && !this.isSolicitante) {
        patch('/pedidos/carpool/quitar',
          this.paxToBeRemoved)
          .then(() => {
            this.$toast.success('Pasajero quitado con éxito');
            this.$emit('close');
            eventBus.$emit('refreshPedidos');
          })
          .catch(() => {
            this.$toast.error('Ocurrió un error al anular el pedido');
          })
          .finally(() => {
            this.loading = false;
            this.$emit('close');
          });
      } else {
        api.delete(this.href, {
          headers: getHeaders(),
        }).catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        }).finally(() => {
          this.loading = false;
          this.$emit('update');
          this.$emit('close');
        });
      }
    },
  },
  computed: {
    ...mapStateTyped(['user']),
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
    isCarpool(): boolean {
      return this.pedido.extras.pasajes.some((pax) => pax.idCarpool);
    },
    isSolicitante(): boolean {
      if (this.pedido.extras.type === 'v') {
        return this.pedido.extras.pasajes.some((pax) => pax.nombreSolicitante === `${this.user?.apellido}, ${this.user?.nombre}`);
      }
      return this.user?.username === this.pedido.extras.solicitante.username;
    },
    paxToBeRemoved(): QuitarPasajeRq[] {
      const paxToBeRemoved: QuitarPasajeRq[] = [];
      this.pedido.extras.pasajes
        .filter((pax) => pax.pasajero?.username === this.user?.username)
        .forEach((pax) => {
          paxToBeRemoved.push({
            idPasaje: pax.idPasaje,
            idPedido: this.pedido.id,
            username: pax.pasajero?.username || '',
          });
        });
      return paxToBeRemoved;
    },
  },
});
