
import Address from './indexAddress.vue';

export default Address.extend({
  computed: {
    isDestination(): boolean {
      return false;
    },
    error() {
      if (this.origenDestinoIguales) return 'Seleccione un origen válido';
      if (!this.userTriedToConfirm) return '';
      if (this.selectedAddress) return '';
      return 'Seleccione un origen';
    },
    headerText(): string {
      if (this.metadata.type === 'PARAMI') {
        return '¿Dónde te buscamos?';
      }
      if (this.metadata.type === 'CONPARADAS') {
        return '¿Dónde los buscamos?';
      }
      if (this.metadata.type === 'RECORRIDOFIJO') {
        return 'Elegí un origen';
      }
      return '¿Dónde lo buscamos?';
    },
  },
});
