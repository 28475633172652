import { capitalizeFirstLetter } from '@/services/utils';

/** Forma de pago que una cuenta puede tener habilitada o no.
 *
 * El hecho de que haya una forma de pago "deshabilitada" significa que
 * el cliente padre tiene *habilitada* esa forma de pago, por lo tanto
 * la cuenta *podría tener habilitada* esa forma de pago, aunque no sea el caso.
 *
 * O sea, cada cliente puede usar ciertas formas de pago y otras no. Por ejemplo, Arcor
 * puede usar "Efectivo", "Tarjeta" y "Cta Corriente" pero Masivos Docta solo puede usar
 * "Efectivo" y "Tarjeta".
 *
 * Cada cuenta de Arcor pueder tener habilitada las tres formas de pago, o solo algunas, o ninguna.
 * Entonces una CorporateAccount de Arcor va a tener en su arreglo "formasDePago" a las tres formas
 * de pago de Arcor, pero la propiedad "habilitada" va a ser `true` solamente para algunas de esas
 * formas de pago.
 *
 * Una cuenta de Masivos Docta, por el contrario, solo va a tener a "Efectivo" en el arreglo
 * "formasDePago". Y esa forma de pago puede estar o no habilitada.
 *
 * Hay algunos detalles pendientes:
 *
 * 1. Una cuenta puede tener cero formas de pago habilitadas?
 * 2. Un cliente puede tener cero formas de pago habilitadas?
 *
 * En caso de que la respuesta a cualquiera de las preguntas sea NO, corresponde que el
 * backend realice la validación.
 *
 * En los datos que hay cargados *actualmente*, no existe ninguna cuenta ni cliente con cero formas
 * de pago.
*/
export type FormaDePago = {
  id: number,
  alias: string
}

export type GenericCeCo = {
  id: number;
  alias: string;
}

export function formatPayMethod(pm: string): string {
  const pmLower = pm.toLowerCase();
  switch (pmLower) {
    case 'ctacte':
      return 'Cuenta corriente';
    default:
      return capitalizeFirstLetter(pmLower);
  }
}

/** Permisos
 */
export type Permissions = {
  approveCotizaciones: boolean,
  changeDefaultCeco: boolean,
  manageEmpleados: boolean,
  manageGroups: boolean,
  registerBoxRequest: boolean,
  registerBusRequest: boolean,
  registerExpressRequest: boolean,
  registerGoRequest: boolean,
  registerStopRequest: boolean,
  registerCarpoolRequest: boolean,
  requestExclusiveVehicle: boolean,
  showAcuerdos: boolean,
  showClientPedidos: boolean,
  showGroupPedidos: boolean,
  showCeCoPedidos: boolean,
  showReports: boolean,
  travel: boolean,
}

/** TX-1939 Ocultar algunos permisos que no se usan.
 * Los mismos permisos que se ocultan acá hay que ocultarlos en EditUserForm
 * para que no sean editables.
 */
export function showPermission(permission: keyof Permissions): boolean {
  switch (permission) {
    case 'showAcuerdos':
    case 'approveCotizaciones':
    case 'showClientPedidos':
    case 'showCeCoPedidos':
    case 'showGroupPedidos':
      return false;
    default:
      return true;
  }
}

/** Centro de costo.
 */
export type CentroDeCosto = {
  id: number,
  denominacion: string | null,
}
