



















import Vue from 'vue';
import { capitalizeText, propOfType } from '@/services/utils';
import { ServicioDisponible } from '@/models/taaxii.com/taaxiiServicioDisponible';
import RouteTimeline from './RouteTimeline.vue';
import { AddressOfForm } from './indexForm.vue';

export default Vue.extend({
  components: {
    RouteTimeline,
  },
  props: {
    distanceKm: propOfType<number>(),
    addresses: propOfType<AddressOfForm[]>(() => []),
    date: propOfType<Date | null>(null),
    service: propOfType<ServicioDisponible | null>(null),
    type: propOfType<string>(),
  },
  data() {
    return {
    };
  },
  computed: {
    cotizacion(): string {
      const { service } = this;
      if (!service) return '-';
      if (this.type === 'RECORRIDOFIJO') return `$${service.precotizacion} por pasajero`;
      const kms = this.distanceKm?.toFixed(2) || '-';
      const price = service.precotizacion > 0
        ? `$${service.precotizacion}` : 'Sin cotización';
      return `${price} • ${kms}Km`;
    },
    details(): [string, string][] {
      const { service } = this;
      return [
        ['Tipo de Servicio', this.tipoDeServicio],
        ['Fecha y Hora de Salida', this.date ? this.formatDate(this.date) : 'Lo antes posible'],
        ['Cotización', this.cotizacion],
      ];
    },
    tipoDeServicio(): string {
      if (this.type === 'RECORRIDOFIJO') return 'Recorrido Fijo';
      return this.service ? capitalizeText(this.service.tipoVehiculo) : '-';
    },
  },
  methods: {
    formatDate(date: Date): string {
      const string = `${date.toLocaleDateString('es-AR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })} hs`;
      // Capitalize first letter
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
});
