































































































































































import Vue from 'vue';
import IconExclamationWhite from '@/components/icons/IconExclamationWhite.vue';
import userMsg from '@/services/userMsg';
import Pedido, { PedidoLink } from '@/models/Pedido';
import { propOfType, capitalizeText, getFullName } from '@/services/utils';
import IconExternalLink20 from '@/components/icons/IconExternalLink20.vue';
import { SolicitudParada, SolicitudPasaje } from '@/models/ExtrasData';
import { mapStateTyped } from '@/services/store/utils';
import { eventBus } from '@/main';
import TextWithLabel from '../TextWithLabel.vue';
import More from '../details/indexDetails.vue';
import MoreMobile from '../details/indexDetailsMobile.vue';
import Calification from './modals/calification/indexCalification.vue';
import Cancellation from './modals/cancellation/indexCancellation.vue';
import CarpoolRemovePaxs from './modals/carpoolEditPaxs/CarpoolRemovePaxs.vue';
import CarpoolAddPaxs from './modals/carpoolEditPaxs/CarpoolAddPaxs.vue';
import CarpoolAddPaxsWithStops from './modals/carpoolEditPaxs/CarpoolAddPaxsWithStops.vue';

export default Vue.extend({
  components: {
    TextWithLabel,
    More,
    Calification,
    Cancellation,
    IconExternalLink20,
    IconExclamationWhite,
    MoreMobile,
    CarpoolRemovePaxs,
    CarpoolAddPaxs,
    CarpoolAddPaxsWithStops,
  },
  props: {
    pedido: propOfType<Pedido>(),
    hideActions: { type: Boolean, default: false },
  },
  methods: {
    openModal(modal: string) {
      if (modal === 'calificar') {
        this.$logEvent('calificar', { accion_calificar: 'boton_calificar' });
      }

      const query = { ...this.$route.query, modal };
      this.$router.replace({
        query,
      });
    },
    onClose() {
      // Quitar modal query
      const query = { ...this.$route.query };
      delete query.modal;
      this.$router.replace({
        query,
      });
    },
    fromRelToComponent(rel: string) {
      switch (rel) {
        case 'calificar': return Calification;
        case 'anular': return Cancellation;
        default:
          // eslint-disable-next-line no-console
          console.error('rel no definido.');
          return null;
      }
    },
    formatAddress(address: string | undefined): string {
      if (address === undefined) return '-';
      return capitalizeText(address.split(',', 2).join(','));
    },
    formatHref(href: string): string {
      return href.startsWith('http:') && !href.includes('localhost')
        ? href.replace('http', 'https') : href;
    },
    copy(str: string) {
      navigator.clipboard.writeText(str)
        .then(() => this.$toast.success(userMsg.getSuccess('string_copied')));
    },
    updatePedido() {
      eventBus.$emit('updatePedido', this.pedido);
    },
  },
  computed: {
    ...mapStateTyped(['user']),
    hrefSeguimiento(): string {
      return this.pedido.actions.find(({ rel }) => rel === 'seguimiento')?.href || '-';
    },
    modal(): string | null {
      // Cuidado este "as", no sé por qué es necesario.
      return this.$route.query.modal as string;
    },
    actions(): PedidoLink[] {
      if (this.hideActions) return [];
      return this.pedido.actions.filter((action) => action.rel !== 'seguimiento'
      && (!this.passenger?.anulando || action.rel !== 'anular')
      // TX-4619 ocultar el botón de anular en recorrido fijo
      && (!this.isCarpool || action.rel !== 'anular'));
    },
    href(): string | undefined {
      return this.actions.find((action) => action.rel === this.modal)?.href;
    },
    pasajes(): SolicitudPasaje[] {
      return this.pedido.extras.pasajes;
    },
    paradas(): SolicitudParada[] {
      return this.pedido.extras.paradas;
    },
    idSolicitud(): number {
      return this.passenger ? this.passenger.idSolicitud
        : this.pedido.extras.pasajes[0].idSolicitud;
    },
    idViaje(): number | undefined {
      if (this.pedido.extras.type === 'v') {
        return this.pedido.extras.idViaje;
      }
      return this.pasajes.find((pax) => pax.idViaje !== null)?.idViaje;
    },
    idCarpool(): number | null {
      return this.pasajes.find((pax) => pax.idCarpool)?.idCarpool || null;
    },
    passengerName(): string {
      return this.passenger ? getFullName(this.passenger.pasajero) : '';
    },
    passenger(): SolicitudPasaje | null {
      if (this.pedido.type !== 'trip') {
        return null;
      }
      const passenger = this.pasajes.find(({ pasajero }) => (
        getFullName(pasajero).toLowerCase()
          === `${this.user?.apellido}, ${this.user?.nombre}`.toLowerCase()
      )) || this.pasajes[0];

      return passenger;
    },
    servicio(): string {
      if (this.pedido.extras.type === 'v') {
        return this.pedido.extras.vehiculo?.tipoVehiculo.alias || 'No Informado';
      }
      return this.passenger?.servicio || this.pedido.extras.pasajes[0].servicio;
    },
    paxsNames(): string[] {
      if (this.pedido.type === 'trip' && this.pasajes.length) {
        return this.pasajes.reduce((total, { pasajero }) => {
          if (pasajero) {
            const paxName = getFullName(pasajero);
            if (paxName.toLowerCase() !== this.passengerName.toLowerCase()
                && !total.includes(paxName)) {
              total.push(capitalizeText(paxName));
            }
          }
          return total;
        }, [] as string[]);
      }
      return [];
    },
    packages(): string[] {
      if (this.pedido.type === 'trip' && this.pasajes.length) {
        const paxDespachante = getFullName(this.passenger?.despachante);
        const paxReceptor = getFullName(this.passenger?.receptor);
        return this.pasajes.reduce((total, { despachante, receptor }) => {
          if (despachante && receptor && getFullName(despachante) !== paxDespachante
              && getFullName(receptor) !== paxReceptor) {
            total.push(`Envío de ${getFullName(despachante)} a ${getFullName(receptor)}`);
          }
          return total;
        }, [] as string[]);
      }
      return [];
    },
    paxSubtext(): string {
      const paxs = this.paxsNames.length;
      const packages = this.packages.length;
      if (!paxs && !packages) return '';
      return paxs && packages
        ? `+ ${paxs} pasajero${paxs > 1 ? 's' : ''} y ${packages} envío${packages > 1 ? 's' : ''}`
        : `+ ${paxs || packages} ${paxs ? 'pasajero' : 'envío'}${paxs + packages > 1 ? 's' : ''}`;
    },
    paxTooltip(): string {
      return this.paxsNames.length || this.packages.length ? [
        ...this.paxsNames,
        ...this.packages,
      ].join('<br/>') : '';
    },
    StopNames(): string[] {
      if (this.pedido.service === 'InPunto') {
        let idOrigen = this.paradas[0].idParada;
        let idDestino = this.paradas[this.paradas.length - 1].idParada;
        if (this.passenger) {
          const idAsc = this.pasajes
            .find((pax) => getFullName(pax?.pasajero) === getFullName(this.passenger?.pasajero))
            ?.idPasaje || this.passenger.idPasaje;
          const idDesc = [...this.pasajes].reverse()
            .find((pax) => getFullName(pax?.pasajero) === getFullName(this.passenger?.pasajero))
            ?.idPasaje || this.passenger.idPasaje;
          idOrigen = this.paradas
            .find(({ idPasajesAsc }) => idPasajesAsc.some((id) => id === idAsc))?.idParada
            || idOrigen;
          idDestino = this.paradas
            .find(({ idPasajesDes }) => idPasajesDes.some((id) => id === idDesc))?.idParada
            || idDestino;
        }
        const origen = this.paradas
          .find((stop) => stop.idParada === idOrigen)?.dir.direccion || '-';
        const destino = this.paradas
          .find((stop) => stop.idParada === idDestino)?.dir.direccion || '-';
        const stops = this.paradas
          .filter(({ idParada }) => idParada !== idOrigen && idParada !== idDestino)
          .map(({ dir }) => this.formatAddress(dir.direccion)).join('<br />');
        return [
          this.formatAddress(origen),
          this.formatAddress(destino),
          stops,
        ];
      }
      return [];
    },
    detalles(): { [key in string]?: string } {
      const pasaje = this.passenger || this.pasajes[0];
      return {
        tipo: `${formatTipoDePedido(this.pedido.type)} | Servicio ${this.servicio}`,
        estado: this.pedido.status,
        cliente: capitalizeText(this.pedido.extras.cliente?.alias || ''),
        fechaHoraSalida: formatDateTime(this.pedido.extras.inicio),
        despachante: pasaje.tipo === 'ASIENTO VACÍO' ? '' : getFullName(pasaje.despachante) || '',
        receptor: getFullName(pasaje.receptor) || '',
      };
    },
    mostrarBotonDeSeguirPedido(): boolean {
      return Boolean(this.pedido.actions.find((action) => action.rel === 'seguimiento'));
    },
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
    alertMessage(): string {
      if (this.passenger?.anulando) {
        return 'Estamos procesando la solicitud de anulación de este pedido';
      }
      if (this.pedido.actions.find((action) => action.rel === 'calificar')) {
        return 'Tu calificación está pendiente y es importante para mejorar el servicio';
      }
      return '';
    },
    isCarpool(): boolean {
      return this.pedido.extras.pasajes.some((pax) => pax.idCarpool);
    },
    isSolicitante(): boolean {
      if (this.pedido.extras.type === 'v') {
        return this.pasajes.some((pax) => pax.nombreSolicitante === `${this.user?.apellido}, ${this.user?.nombre}`);
      }
      return this.user?.username === this.pedido.extras.solicitante.username;
    },
    removeAvailable(): boolean {
      return this.isCarpool
          && this.isSolicitante
          && this.pasajes.some((pax) => pax.tipo !== 'ASIENTO VACÍO')
          && (this.pedido.status === 'confirmado' || this.pedido.status === 'registrado')
          && (this.pedido.substatus === '' || this.pedido.substatus === 'a origen');
    },
    addAvailable(): boolean {
      return this.isCarpool
          && this.isSolicitante
          && this.pasajes.some((pax) => pax.tipo === 'ASIENTO VACÍO')
          && (this.pedido.status === 'confirmado' || this.pedido.status === 'registrado')
          && (this.pedido.substatus === '' || this.pedido.substatus === 'a origen');
    },
    tieneParadas(): boolean {
      return this.paradas.length > 2;
    },
  },
});

/** Convierte `Pedido#type` a un string más user-friendly */
function formatTipoDePedido(pedidoType: string): string {
  switch (pedidoType.toLowerCase()) {
    case 'pasajero':
    case 'trip':
      return 'Viaje';
    case 'mensajería':
    case 'mensajeria':
    case 'paquete':
    case 'bulto':
    case 'package':
      return 'Envío';
    default:
      return pedidoType[0].toUpperCase() + pedidoType.substring(1).toLowerCase();
  }
}

/** Formatea la fecha-hora. Ejemplo: "Miércoles, 13 de octubre de 2021 a las 13:25"
*/
function formatDateTime(date: Date): string {
  return `${formatDate(date)}, ${formatTime(date)}hs`;
}

/** Devuelve una hora en formato 00:00 */
function formatTime(date: Date): string {
  const pad = (n: number) => (n < 10 ? `0${n}` : String(n));

  return `${pad(date.getHours())}:${pad(date.getMinutes())}`;
}

function formatDate(date: Date): string {
  return date.toLocaleDateString('es-AR', {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
  });
}

