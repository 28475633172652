














































import Vue from 'vue';
import { capitalizeText, propOfType } from '@/services/utils';
import { get } from '@/services/http';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import msg from '@/services/userMsg';
import { gralAddress } from '@/services/api/addressApi';
import { eventBus } from '@/main';
import BigButton from './form/BigButton.vue';
import RouteTimeline from './form/RouteTimeline.vue';
import { Metadata } from './CreateWeb.vue';
import { DataOfSomeForm } from './form/indexForm.vue';

export default Vue.extend({
  components: {
    BigButton,
    RouteTimeline,
  },
  props: {
    setMapAddresses: Function,
    exit: Function,
    metadataOfForm: propOfType<Metadata>(),
    dataOfForm: propOfType<DataOfSomeForm>(),
    distanceKm: Number,
  },
  data() {
    return {
      selected: 0 as number,
    };
  },
  watch: {
    selected() {
      this.$emit('toggleMap');
    },
  },
  mounted() {
    get('$sgv/rest/solicitudes/$idSolicitud', { idSolicitud: this.pedidoNumberText })
      .then((response) => {
        const estadoSolicitud = response.data.solicitudes[this.pedidoNumberText].estado;
        if (estadoSolicitud === 'B') {
          this.$toast.info('Tu pedido se completó con éxito, pero necesita autorización');
        }
      })
      .catch((error) => {
        this.$toast.error(msg.getError(errorCodeFromAxiosError(error)));
      });

    this.dataOfForm.addresses.forEach((adds, i) => {
      if (adds) {
        gralAddress.addUse({ id: adds.id, type: i === 0 ? 'ORIGEN' : 'DESTINO' })
          .catch(() => {});
      }
    });
    eventBus.$emit('grow-bottomsheet');
  },
  computed: {
    headerText(): string {
      return `¡${this.metadataOfForm.name} registrado!`;
    },
    subtitleText(): string {
      return this.pedidoNumberText;
    },
    pedidoNumberText(): any {
      const res = !this.selected
        ? this.dataOfForm.finalDataResponse
        : this.dataOfForm.finalDataResponseRegreso;
      if (res) {
        const url = res.links[0].href.split('/');
        return url[url.length - 1];
      }
      return '-';
    },
    cotizacion(): string {
      const { service } = this.dataOfForm;
      if (!service) return '-';
      if (this.metadataOfForm.type === 'RECORRIDOFIJO') return `$${service.precotizacion} por pasajero`;
      const kms = this.distanceKm?.toFixed(2) || '-';
      const suffix = this.dataOfForm.dateRegreso ? ' (por tramo)' : '';
      const price = service.precotizacion > 0
        ? `$${service.precotizacion}${suffix}`
        : 'Sin cotización';
      return `${price} • ${kms}Km`;
    },
    origin(): string {
      const [origin] = this.dataOfForm.addresses;
      if (origin) return origin.street;
      return '-';
    },
    destination(): string {
      const [_, destination] = this.dataOfForm.addresses;
      if (destination) return destination.street;
      return '-';
    },
    date(): Date | null {
      return !this.selected
        ? this.dataOfForm.date
        : this.dataOfForm.dateRegreso;
    },
    details(): [string, string][] {
      const { service } = this.dataOfForm;
      if (this.conParadas) {
        return [
          ['Tipo de Servicio', this.tipoDeServicio],
          this.dayAndHour,
          ['Cotización', this.cotizacion],
        ];
      }
      return [
        // Ver requerimientos de TX-2770
        ['Tipo de Pedido', this.metadataOfForm.name.replace('Envíos', 'Envío')],
        ['Tipo de Servicio', service
          ? `${capitalizeText(service.tipoVehiculo)}` : '-'],
        this.rangoFechas,
        this.dayAndHour,
        ['Origen', this.origin],
        ['Destino', this.destination],
        ...this.datosEnvio,
        ['Cotización', this.cotizacion],
      ];
    },
    tipoDeServicio(): string {
      if (this.metadataOfForm.type === 'RECORRIDOFIJO') return 'Recorrido Fijo';
      return this.dataOfForm.service ? capitalizeText(this.dataOfForm.service.tipoVehiculo) : '-';
    },
    conRetorno(): boolean {
      return ((this.dataOfForm.dateRegreso && !this.dataOfForm.days.length)
      || this.dataOfForm.daysRegreso.length) as boolean;
    },
    conParadas(): boolean {
      return this.metadataOfForm.type === 'CONPARADAS' || this.metadataOfForm.type === 'RECORRIDOFIJO';
    },
    isPlanificado(): boolean {
      return Boolean(this.dataOfForm.days.length);
    },
    rangoFechas(): [string, string] {
      if (this.isPlanificado) {
        const date = this.formatDay(this.dataOfForm.date as Date);
        const dateRegreso = this.formatDay(this.dataOfForm.dateRegreso as Date);
        return ['Rango de fechas', `Desde el ${date} hasta el ${dateRegreso}`];
      }
      return ['', ''];
    },
    dayAndHour(): [string, string] {
      if (this.isPlanificado) {
        const title = !this.selected ? 'Dias y hora de ida' : 'Dias y hora de regreso';
        const days = !this.selected ? this.parseDays(this.dataOfForm.days)
          : this.parseDays(this.dataOfForm.daysRegreso);
        const time = !this.selected ? this.formatTime(this.dataOfForm.date as Date)
          : this.formatTime(this.dataOfForm.dateRegreso as Date);
        return [title, `Todos los ${days} a las ${time}`];
      }
      return ['Fecha y Hora de Salida', this.date ? this.formatDate(this.date) : 'Lo antes posible'];
    },
    datosEnvio(): Array<[string, string]> {
      if (this.dataOfForm.type === 'MENSAJERIA' || this.dataOfForm.type === 'PAQUETERIA') {
        const { dispatcherNameAndLastName, receiverNameAndLastName, observacion } = this.dataOfForm;
        return [
          ['Emisor', capitalizeText(dispatcherNameAndLastName)],
          ['Receptor', capitalizeText(receiverNameAndLastName)],
          ['Descripción del envío', observacion],
        ];
      }
      return [['', '']];
    },
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
  },
  methods: {
    formatDate(date: Date): string {
      const string = `${date.toLocaleDateString('es-AR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })} hs`;
      // Capitalize first letter
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatDay(date: Date): string {
      const string = `${date.toLocaleDateString('es-AR', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      })}`;
      // Capitalize first letter
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatTime(date: Date): string {
      const string = `${date.toLocaleTimeString('es-AR', {
        hour: '2-digit',
        minute: '2-digit',
      })} hs`;
      // Capitalize first letter
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    parseDays(daysSelected: number[]): string {
      const dayNames = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'];
      const days = [] as String[];
      daysSelected.forEach((day) => { days.push(dayNames[day]); });
      return days.length ? days.join(', ') : 'Seleccione un día';
    },
  },
});
