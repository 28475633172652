/* eslint max-classes-per-file: ["error", 2] */
import type { GenericCeCo } from './accounts/shared';
import SimpleAddress from './simpleAddress';
import GenericPerson from './taaxii.com/taaxiiGenericPerson';

export class SolicitudParada {
  idParada: number;

  fechaHora: Date;

  obs: string;

  dir: SimpleAddress;

  idPasajesAsc: number[];

  idPasajesDes: number[];

  constructor(data) {
    this.idParada = data.idParada;
    this.fechaHora = new Date(data.fechaHora);
    this.obs = data.obs;
    this.dir = data.dir;
    this.idPasajesAsc = data.idPasajesAsc;
    this.idPasajesDes = data.idPasajesDes;
  }
}

export class SolicitudPasaje {
  idSolicitud: number;

  idPasaje: number;

  idViaje: number;

  idCarpool: number

  paradas: number[];

  estado: string;

  canal: string;

  tipo: string;

  servicio: string;

  ceco: GenericCeCo;

  obs: string;

  anulando: boolean;

  pasajero?: GenericPerson;

  receptor?: GenericPerson;

  despachante?: GenericPerson;

  nombreSolicitante: string;

  legajoSolicitante: string;

  calificacion: number;

  coefProrrateo: number;

  nroLiquidacion?: number;

  constructor(data) {
    this.idSolicitud = data.idSolicitud;
    this.idPasaje = data.idPasaje;
    this.idViaje = data.idViaje;
    this.idCarpool = data.idCarPool;
    this.paradas = data.paradas;
    this.estado = data.estado;
    this.canal = data.canal;
    this.tipo = data.tipo;
    this.servicio = data.servicio;
    this.ceco = data.ceco;
    this.obs = data.obs;
    this.anulando = data.anulando;
    this.pasajero = data.pasajero ? data.pasajero : undefined;
    this.receptor = data.receptor ? data.receptor : undefined;
    this.despachante = data.despachante ? data.despachante : undefined;
    this.nombreSolicitante = data.nombreSolicitante;
    this.legajoSolicitante = data.legajoSolicitante;
    this.calificacion = data.calificacion;
    this.coefProrrateo = data.coefProrrateo;
    this.nroLiquidacion = data.nroLiquidacion ? data.nroLiquidacion : undefined;
  }
}
