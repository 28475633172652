




























































import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import { propOfType, debounce } from '@/services/utils';
import { PersonWithCC } from '@/models/Person';
import msg from '@/services/userMsg';
import { get } from '@/services/http';
import { CuentasBusquedaRequest } from '@/models/requests-and-responses';
import store from '@/services/store';
import { GeneralAccount, getIdCliente } from '@/models/accounts/GeneralAccount';
import axios from 'axios';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';

export type Pax = {
  nameAndLastName: string,
  phone: string,
  phoneIsValid: boolean,
  person?: PersonWithCC | null
}

export default Vue.extend({
  data() {
    return {
      nameAndLastName: '',
      phone: '',
      phoneIsValid: false,
      person: null as PersonWithCC | null,
      suggestions: [] as PersonWithCC[],
      openSuggestions: true,
      nameLabel: 'Apellido, Nombre',
      phoneLabel: `Número de teléfono ${this.optionalPhone ? ' (opcional)' : ''}`,
      personIsSetted: false,
      debounceFetch: (...args) => {},
      searching: false,
      abortController: null as null | AbortController,
    };
  },
  props: {
    showLabels: propOfType<Boolean>(false),
    showAsRows: propOfType<Boolean>(false),
    focusOnName: propOfType<Boolean>(false),
    autocomplete: propOfType<Boolean>(false),
    showSuggestions: propOfType<Boolean>(true),
    initialData: propOfType<Pax | null>(null),
    optionalPhone: propOfType<Boolean>(false),
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    if (this.initialData) {
      this.nameAndLastName = this.initialData.nameAndLastName;
      this.phone = this.initialData.phone;
      this.phoneIsValid = this.initialData.phoneIsValid;
      this.person = this.initialData.person || null;
    }

    this.debounceFetch = debounce(() => { this.fetchSuggestions(); }, 500);
  },
  methods: {
    requestFocusToNameAndLastName() {
      const el = this.$refs.inputNameAndLastName as Vue;
      const childenInputEl = el.$refs.nameInput as HTMLInputElement;
      Vue.nextTick(() => {
        childenInputEl.focus();
      });
    },
    onSuggestionClick(suggestion: PersonWithCC) {
      this.suggestions = [];
      this.openSuggestions = false;
      this.nameAndLastName = `${suggestion.lastname}, ${suggestion.firstname}`;
      this.phone = suggestion.phone;
      this.person = suggestion;
      this.personIsSetted = true;
      this.$emit('personChange', this.pax);
    },
    onNameAndLastNameInput() {
      if (this.showSuggestions) {
        this.openSuggestions = true;
        if (this.personIsSetted) {
          this.personIsSetted = false;
          this.person = null;
          this.$emit('personChange', this.pax);
        }
      }
    },
    updatePerson() {
      this.$emit('personChange', this.pax);
    },
    fetchSuggestions() {
      const query = this.nameAndLastName;
      if (!this.showSuggestions || !query) {
        this.suggestions = [];
        this.searching = false;
        return;
      }
      const { idCliente } = this;
      if (idCliente === null) {
        // eslint-disable-next-line no-console
        console.error('idCliente es null y no debería serlo.');
        this.searching = false;
        return;
      }
      const request: CuentasBusquedaRequest = {
        idCliente,
        length: 5,
        offset: 0,
        query,
      };

      if (this.searching && this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      let requestWasCancelled = false;

      get('/cuentas/busqueda', request, { signal: this.abortController?.signal }).then((res) => {
        this.suggestions = res.data;
      })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            this.$toast.error(msg.getError(errorCodeFromAxiosError(error)));
          } else {
            requestWasCancelled = true;
          }
        })
        .finally(() => {
          if (!requestWasCancelled) {
            this.abortController = null;
            this.searching = false;
          }
        });
    },
    onArrowKeyPress(index: number) {
      let i = index;
      if (this.suggestions.length) {
        if (i < 0) i = this.suggestions.length - 1;
        if (i > this.suggestions.length - 1) i = 0;
        this.$refs[i][0].focus();
      }
    },
  },
  watch: {
    nameAndLastName(val) {
      if (!this.personIsSetted && this.showSuggestions) {
        this.searching = true;
        this.debounceFetch();
      }

      if (!val) {
        this.phone = '';
        this.person = null;
        this.personIsSetted = false;
      }

      this.updatePerson();
    },
    phone(val: string) {
      if (!val) return;
      if (!val.startsWith('+') && val.length > 8) {
        if (val.startsWith('54')) this.phone = `+${val}`;
        if (!val.startsWith('+54')) this.phone = `+54${val}`;
      }
      this.updatePerson();
    },
    phoneIsValid() {
      this.updatePerson();
    },
    focusOnName(val) {
      if (val) {
        this.requestFocusToNameAndLastName();
      }
    },
    initialData(val: Pax | null) {
      if (val) {
        this.personIsSetted = true;
        this.nameAndLastName = val.nameAndLastName;
        this.phone = val.phone;
        this.phoneIsValid = val.phoneIsValid;
        this.person = val.person || null;
      }
    },
  },
  computed: {
    selectedAccount(): GeneralAccount | null { return store.getters.selectedAccount; },
    idCliente(): number | null {
      return this.selectedAccount !== null ? getIdCliente(this.selectedAccount) : null;
    },
    wrapperClass(): string {
      return this.showAsRows ? 'flex flex-row gap-4 w-full'
        : 'grid grid-cols-1 mx-4 xs:ml-12 xs:mr-8 gap-4';
    },
    pax(): Pax {
      const {
        nameAndLastName,
        phone,
        phoneIsValid,
        person,
      } = this;

      return {
        nameAndLastName,
        phone,
        phoneIsValid,
        person,
      };
    },
  },
});
